@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-Italic.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-Regular.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-LightItalic.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-Bold.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-Light.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('src/roboto-condensed/RobotoCondensed-BoldItalic.woff2') format('woff2'),
        url('src/roboto-condensed/RobotoCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

